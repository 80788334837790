<template>
  <main id="project" class="p-0">
    <template v-if="isLoading">{{ $t("main.loading") }}</template>
    <template v-else>
      <div id="project-text" class="p-6 lg:p-16">
        <h1 id="project-title" class="text-2xl font-bold lg:text-3xl 2xl:text-4xl mb-2">{{ project.name }}</h1>
        <div class="project-skills text-gray-500 dark:text-gray-400 mb-4 2xl:mb-6">
          <span
            v-for="skill in localeContent.skills"
            :key="skill"
            class="text-sm lg:text-base font-bold uppercase mr-2 block lg:inline"
            >{{ skill }}</span>
        </div>
        <p class="2xl:text-xl" v-html="localeContent.description"></p>
      </div>
      <ul id="project-images">
        <li v-for="image in project.gallery" :key="image.url" :url="image.url">
          <ImageContainer :source="'/img/' + image.url"></ImageContainer>
        </li>
      </ul>
      <div id="project-suggested" class="p-6 lg:p-16">
        <Heading2>{{ $t("main.moreProjects") }}</Heading2>
        <Divider/>
        <ul class="lg:grid grid-cols-2 gap-4">
          <ProjectItem
            v-for="project in randomProjects"
            :key="project.id"
            :id="project.id"
            :name="project.name"
            :header="project.header"
            :content="project.content"
          ></ProjectItem>
        </ul>
      </div>
    </template>
  </main>
</template>

<script>
import ProjectItem from "../components/layout/ProjectItem.vue";
import ImageContainer from "../components/ui/ImageContainer.vue";
import Heading2 from "../components/ui/Heading2.vue"
import Divider from "../components/ui/Divider.vue"

export default {
  props: ["projectId"],
  components: {
    ProjectItem,
    ImageContainer,
    Heading2,
    Divider,
  },
  data() {
    return {
      isLoading: false,
      project: {},
    };
  },
  methods: {
    loadProject(projectId) {
      const selectedProject = this.$store.getters["portfolio/portfolio"].find(
        (project) => project.id === projectId
      );
      this.project = selectedProject;
      console.log("Project Loaded: " + this.project.name);
    },
  },
  computed: {
    randomProjects() {
      const portfolio = this.$store.getters["portfolio/portfolio"].filter(
        (project) => this.projectId !== project.id
      );

      let currentIndex = portfolio.length;
      let temporaryValue;
      let randomIndex;

      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = portfolio[currentIndex];
        portfolio[currentIndex] = portfolio[randomIndex];
        portfolio[randomIndex] = temporaryValue;
      }

      return portfolio.splice(portfolio.length - 2);
    },
    localeContent() {
      return this.project.content[this.$i18n.locale];
    }
  },
  watch: {
    projectId(newId) {
      this.loadProject(newId);
    },
  },
  beforeRouteUpdate() {
    this.loadProject(this.projectId);
  },
  created() {
    this.loadProject(this.projectId);
    document.title = this.project.name + " | Piero del Valle";
  },
  updated() {
    document.title = this.project.name + " | Piero del Valle";
  },
};
</script>

<style>
</style>